<template>
  <CRow class="form-group">
    <CCol sm="12">
      {{ value }}
      <ValidationProvider :rules="rules" immediate v-slot="{ errors }">
        {{ rules }}
        {{ errors }}
        <CTextarea
          :label="formLabel"
          :rows="rows"
          v-bind:value="value"
          v-on:input="inputEvent"
          :isValid="errors.length == 0"
          style="margin-bottom: 0.5rem;"
        >
          <template #invalid-feedback>
            <em class="error invalid-feedback">Das Feld darf nicht leer sein.</em>
          </template>
        </CTextarea>
      </ValidationProvider>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'FormTextArea',
  props: {
    formLabel: {
      default: 'Text',
      type: String
    },
    rules: {
      default: '',
      type: String
    },
    rows: {
      default: '9',
      type: String
    },
    value: String
  },
  methods: {
    inputEvent: function (newValue) {
      this.$emit('input', newValue)
    },
    isValidClass: function (errors) {
      let result = 'form-control'
      if (errors.length === 0) {
        result = result + ' is-valid'
      } else {
        result = result + ' is-invalid'
      }
      return result
    }
  }
}
</script>
